import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { Link } from 'preact-router/match';
import { Drawer } from 'antd';
import logo from '../../../assets/logo.png';
import style from './style.css';
import { HomeOutlined, LogoutOutlined, LockOutlined, MenuOutlined } from '@ant-design/icons';
import Context from "../../../context";

const Header = ({isBigBoss, isDirector}) => {
    const [width, setWidth] = useState()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [authority, setAuthority] = useState();

       useEffect(() => {
		setAuthority(isBigBoss || isDirector);
       }, [isBigBoss, isDirector])

	useEffect(() => {
		if (window !== undefined) {
			setWidth(window.innerWidth)
			const handleResize = () => {
				setWidth(window.innerWidth)
			}
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, []);

	if(width < 1200){
		return (
			<>
				<header class={style.header}>
					<div class="jc-center" style="padding-right:16px" onClick={() => setDrawerOpen(true)}>
						<MenuOutlined />
					</div>
					<div class="flex-1 h-100 center" style="padding: 10px 16px 10px 0;cursor: pointer;">
						<h5 onClick={() => route('/onboard')}>My Week</h5>
					</div>
				</header>
				{authority &&
				<Drawer width={'70%'} placement="left"  onClose={() => setDrawerOpen(false)} visible={drawerOpen} closeIcon={false}>
					<div class={style.tailItem} style="margin-left:0px" onClick={() => {setDrawerOpen(false); route('/')}}>
						<span>Page d'accueil</span>
						<HomeOutlined style="margin-left: 16px"/>
					</div>
					<div class={style.tailItem} style="margin-left:0px" onClick={() => {setDrawerOpen(false); route('/backoffice/diagnosis')}}>
						<span>Accès administrateur</span>
						<LockOutlined style="margin-left: 16px" />
					</div>
					<div class={style.tailItem} style="margin-left:0px" onClick={() => {setDrawerOpen(false); route('/login')}}>
						<span>Se déconnecter</span>
						<LogoutOutlined style="margin-left: 16px" />
					</div>
				</Drawer>}
			</>
		)
	}
	else{
		return (
			<header class={style.header}>
				<div class="flex-1 h-100" style="padding: 10px 0;cursor: pointer;">
					<img src={logo} style="height:100%" onClick={() => route('/')} />
				</div>
				{/* <nav>
					<Link activeClassName={style.active} href="/">Home</Link>
					<Link activeClassName={style.active} href="/profile">Me</Link>
					<Link activeClassName={style.active} href="/profile/john">John</Link>
				</nav> */}
				<div>
					<h5>My Week</h5>
				</div>
				<div class="d-row al-center jc-end flex-1">
					{authority && 
					<>
						<div class={style.tailItem} onClick={() => route('/')}>
							<span>Page d'accueil</span>
							<HomeOutlined style="margin-left: 16px"/>
						</div>
						<div class={style.tailItem} onClick={() => route('/backoffice/diagnosis')}>
							<span>Accès administrateur</span>
							<LockOutlined style="margin-left: 16px" />
						</div>
						<div class={style.tailItem} onClick={() => route('/login')}>
							<span>Se déconnecter</span>
							<LogoutOutlined style="margin-left: 16px" />
						</div>
					</>}
				</div>
			</header>
		)
	}
}

export default Header;
